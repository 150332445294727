import { render, staticRenderFns } from "./LinkButton.vue?vue&type=template&id=40e73604&scoped=true&"
import script from "./LinkButton.vue?vue&type=script&lang=js&"
export * from "./LinkButton.vue?vue&type=script&lang=js&"
import style0 from "./LinkButton.vue?vue&type=style&index=0&id=40e73604&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40e73604",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/pbk/pim/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('40e73604')) {
      api.createRecord('40e73604', component.options)
    } else {
      api.reload('40e73604', component.options)
    }
    module.hot.accept("./LinkButton.vue?vue&type=template&id=40e73604&scoped=true&", function () {
      api.rerender('40e73604', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "modules/@ergonode/ui/src/components/LinkButton/LinkButton.vue"
export default component.exports